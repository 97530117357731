import type { SnapshotRecord, User } from "@prisma/client";
import type { ActionArgs } from "@remix-run/node";
import { json } from "@remix-run/node";
import {
  Form,
  Link,
  useCatch,
  useSubmit,
  useTransition,
} from "@remix-run/react";
import { HTTP_STATUS_CODE } from "@wilkins-software/http-headers";
import bcrypt from "bcryptjs";
import type { Dispatch, SetStateAction } from "react";
import toast from "react-hot-toast";
import { BsFacebook, BsMicrosoft } from "react-icons/bs";
import { IoIosArrowDown, IoIosWarning } from "react-icons/io";
import { IoArrowBack } from "react-icons/io5";
import { useLocalStorage } from "usehooks-ts";
import { v4 as uuidV4 } from "uuid";
import { rehashPassword } from "~/helpers/rehash-password";
import { throwJsonResponse } from "~/helpers/throw-json-response.server";
import { useQueryStrings } from "~/hooks/use-query-strings";
import { prisma } from "~/prisma.server";
import {
  createUserSession,
  destroySession,
  getSession,
} from "~/session/sessions.server";
import { useActionSubmissionToast } from "~/hooks/use-action-submission-toast";

export const action = async ({ request }: ActionArgs) => {
  const uuid = uuidV4();
  const body = await request.formData();
  const email = body.get("email") as string | undefined;
  const password = body.get("password") as string | undefined;
  const rememberMe = body.get("rememberMe") as string | undefined;

  let isRehashing = false;
  let hasBeenRehashed: SnapshotRecord | null = null;
  let user: User | null = null;

  if (!email || !password)
    return json(
      { errorMessage: "You must provide an email and password to log in" },
      { status: HTTP_STATUS_CODE.BAD_REQUEST }
    );

  // check if user has rehashed password already or not
  const hasBeenRehashedPromise = prisma.snapshotRecord.findFirst({
    where: {
      AND: [
        {
          recordType: "password_rehash",
        },
        {
          record: {
            path: ["isPasswordCorrect"],
            equals: true,
          },
        },
        {
          record: {
            path: ["user", "email"],
            equals: email,
          },
        },
      ],
    },
  });

  const userPromise = prisma.user.findUnique({ where: { email: email } });

  [user, hasBeenRehashed] = await Promise.all([
    userPromise,
    hasBeenRehashedPromise,
  ]);

  if (!user) {
    const session = await getSession(request.headers.get("Cookie"));
    return throwJsonResponse({
      status: HTTP_STATUS_CODE.NOT_FOUND,
      message: `No User with "${email}" was found. Are you sure you entered your email correctly?`,
      headers: {
        "Set-Cookie": await destroySession(session),
      },
    });
  }

  const isCorrectPassword = await bcrypt.compare(
    password,
    user?.password as string
  );

  if (!isCorrectPassword) {
    const session = await getSession(request.headers.get("Cookie"));
    return throwJsonResponse({
      status: HTTP_STATUS_CODE.UNAUTHORIZED,
      message: "Incorrect Password",
      headers: {
        "Set-Cookie": await destroySession(session),
      },
    });
  }

  if (!hasBeenRehashed) {
    await rehashPassword(user, password);
    await prisma.snapshotRecord.create({
      data: {
        recordType: "password_rehash",
        record: {
          isRehashing: false,
          isRehashed: true,
          updatedAt: Date.now(),
        },
        timeSubmitted: new Date(),
      },
    });
  }

  return createUserSession(user.id.toString(), "/lists");
};

const SignIn = () => {
  const { searchParams } = useQueryStrings();
  const transition = useTransition();
  const isLoading = transition.state !== "idle";

  const [isRememberMeChecked, setRememberMe] = useLocalStorage(
    "remember-me",
    false
  );

  useActionSubmissionToast();

  const err = searchParams.get("error");
  if (err && typeof document !== "undefined") {
    console.log("err", err);
    toast.error(err, { duration: 15000, id: "error-code" });
  }

  return (
    <>
      <Form method="post">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              disabled={isLoading}
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="mt-1">
          <label
            htmlFor="password"
            className="block text-sm font-medium text-gray-700"
          >
            Password
          </label>
          <div className="mt-1">
            <input
              disabled={isLoading}
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
            />
          </div>
        </div>

        <div className="my-2 flex items-center justify-between">
          <div className="flex items-center">
            <input
              disabled={isLoading}
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
              checked={isRememberMeChecked}
              onChange={(e) => setRememberMe(e.target.checked)}
            />
            <label
              htmlFor="remember-me"
              className="ml-2 block text-sm text-gray-900"
            >
              Remember me
            </label>
          </div>

          <div className="text-sm">
            <Link
              to="forgot-password"
              className="font-medium text-primary-600 hover:text-primary-500"
            >
              Forgot your password?
            </Link>
          </div>
        </div>

        <div>
          <button
            type="submit"
            disabled={isLoading}
            className="flex w-full justify-center rounded-md border border-transparent bg-primary-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            Sign in
          </button>
        </div>
      </Form>

      <div className="relative mt-6">
        <div className="absolute right-[105%] hidden rounded-md bg-white p-6 shadow-md md:block">
          <div className="flex">
            <div className="flex-[95%] text-center">
              <span className="relative mx-auto flex justify-center text-center">
                <IoIosWarning className="h-6 w-6 text-yellow-500 " />
                <IoIosWarning className="animate absolute top-0 h-6 w-6 animate-ping text-yellow-500" />
              </span>
              <p>
                Use this button log in using your{" "}
                <span className="rounded-lg bg-green-100 px-2 py-1 text-green-800">
                  @torontohumanesociety.com
                </span>{" "}
                emails in one click!
              </p>
            </div>
            <div className="relative flex-[5%]">
              <div className="-translate-1/2 absolute -right-5 top-[25%] -rotate-90">
                <div className="animate-bounce text-2xl ">
                  <IoIosArrowDown />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="relative">
          <div className="absolute inset-0 flex items-center">
            <div className="w-full border-t border-gray-300" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="bg-white px-2 text-gray-500">
              Or continue with
            </span>
          </div>
        </div>

        <div className="mt-6 grid grid-cols-3 gap-3">
          <div>
            <a
              href="/sign-in/microsoft-identity"
              className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50 md:bg-green-200 md:text-green-800"
            >
              <span className="sr-only">Sign in with Microsoft</span>
              <BsMicrosoft
                className="h-5 w-5"
                aria-hidden="true"
                fill="currentColor"
              ></BsMicrosoft>
            </a>
          </div>

          <div>
            <a
              href="/sign-in/facebook-identity"
              className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
            >
              <span className="sr-only">Sign in with Facebook</span>
              <BsFacebook
                className="h-5 w-5"
                aria-hidden="true"
                fill="currentColor"
              ></BsFacebook>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;

export function CatchBoundary() {
  const caught = useCatch();

  return (
    <div className="prose">
      <h3>Sorry! We weren't able to log you in.</h3>
      {caught.data?.message && (
        <>
          <p>The following error occurred:</p>
          <p className="rounded bg-red-100 p-4 text-red-800">
            {caught.data?.message}
          </p>
        </>
      )}
      <Link
        prefix="render"
        to="/sign-in"
        className="space-between mt-6 flex h-full items-center rounded bg-primary-500 px-4 py-1 text-white no-underline hover:bg-primary-800"
      >
        <IoArrowBack className="mr-4 h-5 w-5" /> Return to Login Page to try
        again
      </Link>
    </div>
  );
}
